import Cookies from 'js-cookie';
import React from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';

const EXCEPTION_PATHS = [
  "/purchase-plan-visitor"
]

const EXCEPTION_PATHS_302: any = {
  "/purchase-plan-visitor": "/purchase-plan"
}

export const CheckPublicRoute = ({ children }: { children: any }) => {
  const auth = Cookies.get('ck-customer');
  const params = useParams();
  const location = useLocation();
  let requestedPath = location.pathname;

  let exceptionFound = false;
  EXCEPTION_PATHS.forEach( (path: string) => {
    if(requestedPath.includes(path)){
      if(auth !== undefined){
        exceptionFound = true;
        requestedPath = `${EXCEPTION_PATHS_302[path]}${ params.slot_number ? "/" + params.slot_number : "" }`;
      }
    }
  })

  if (exceptionFound) return <Navigate to={requestedPath} />;
  return auth ? <Navigate to="/my-profile" /> : children;
};
